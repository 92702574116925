import { useState, useEffect } from "react"

function getWindowDimensions() {
  if (typeof window === "undefined") {
    return { width: 0, height: 0 }
  } else {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height,
    }
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window && window.addEventListener("resize", handleResize)
    return () => window && window.removeEventListener("resize", handleResize)
  }, [])

  return windowDimensions
}
